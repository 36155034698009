<template>
  <tbody>
    <tr>
      <td rowspan="3" style="border-bottom: none">院长签发</td>
      <td rowspan="3" colspan="2" style="border-bottom: none; width: 300px">
        <auditshow
          :infoData="data"
          taskKey="省教科院发文卡-院长签发"
          @callback="auditshowBack"
        />
      </td>
      <td>办公室意见</td>
      <td colspan="2">
        <auditshow
          :infoData="data"
          taskKey="省教科院发文卡-办公室意见"
          @callback="auditshowBack"
        />
      </td>
    </tr>
    <tr>
      <td><span style="color: red">*</span>职能部室</td>
      <td colspan="2">
        <a-select
          v-model="selectOrgs"
          mode="multiple"
          v-if="data.status == 0 || data.status == 3"
          @change="selectOrgschange"
        >
          <a-select-option
            v-for="item in orgDates"
            :key="item.id"
            :value="item.path"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <auditshow
          v-if="data.status == 1 || data.status == 2"
          :infoData="data"
          taskKey="省教科院发文卡-职能部室"
          @callback="auditshowBack"
        />
      </td>
    </tr>
    <tr>
      <td style="border-bottom: none">部室意见</td>
      <td colspan="2" style="border-bottom: none">
        <auditshow
          :infoData="data"
          taskKey="省教科院发文卡-部室意见"
          @callback="auditshowBack"
        />
      </td>
    </tr>
    <tr>
      <td rowspan="3" style="border-bottom: none">主管领导意见</td>
      <td rowspan="3" colspan="2" style="border-bottom: none">
        <auditshow
          :infoData="data"
          taskKey="省教科院发文卡-主管领导意见"
          @callback="auditshowBack"
        />
      </td>
      <td><span style="color: red">*</span>会签意见</td>
      <td colspan="2">
        <a-input-search
          class="select_search"
          placeholder="请选择会签人"
          readOnly
          v-model="data.huiqianName"
          @click="onSearchs"
          @search="onSearchs"
          v-if="data.status == 0 || data.status == 3"
        />
        <auditshow
          v-if="data.status == 1 || data.status == 2"
          :infoData="data"
          taskKey="省教科院发文卡-会签意见"
          @callback="auditshowBack"
        />
      </td>
    </tr>
    <tr>
      <td><span style="color: red">*</span>核稿</td>
      <td colspan="2">
        <a-input-search
          class="select_search"
          placeholder="请选择核稿人"
          readOnly
          v-model="data.hegaoName"
          @click="onSearch"
          @search="onSearch"
          v-if="data.status == 0 || data.status == 3"
        />
        <auditshow
          v-if="data.status == 1 || data.status == 2"
          :infoData="data"
          taskKey="省教科院发文卡-核稿"
          @callback="auditshowBack"
        />
      </td>
    </tr>
    <tr>
      <td style="border-bottom: none">拟稿</td>
      <td colspan="2" style="border-bottom: none; text-align: left">
        <a-button
          v-if="data.status == 0 || data.status == 3"
          type="link"
          @click="showWps"
          >填写正文</a-button
        >
        <a-button v-else type="link" @click="showWps">查看正文</a-button>
        <a-popconfirm
          title="提交后数据不可更改，是否确认？"
          @confirm="handleSubmit(1)"
          v-if="data.status == 0 || data.status == 3"
        >
          <a-button type="link" style="margin-right: 10px">提交</a-button>
        </a-popconfirm>
        <span v-if="data.status > 0"> {{ data.otherExtension.remark }}</span>
        <a-modal
          title="选择用户"
          v-model="selectuservisible"
          width="70%"
          :footer="false"
          destroyOnClose
          :maskClosable="false"
        >
          <selectuser @callback="selectuserCallback" />
        </a-modal>
        <a-modal
          title="选择用户"
          v-model="selectusersvisible"
          width="70%"
          :footer="false"
          destroyOnClose
          :maskClosable="false"
        >
          <selectuser :multi="true" @callback="selectusersCallback" />
        </a-modal>
      </td>
    </tr>
  </tbody>
</template>
<script>
import orgApi from "@/api/org";
import selectuser from "../components/searchuser.vue";
export default {
  name: "yanjiuyuan",
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      data1: this.data,
      orgs: null,
      selectuservisible: false,
      selectusersvisible: false,
      orgsNumber: 0,
      orgDates: [],
      selectOrgs: [],
      selectOrgKeys: [],
    };
  },
  watch: {
    data(v) {
      this.data1 = v;
    },
  },
  mounted() {
    if (this.data.extensionSetting) {
      let fullOrgs =
        this.data.extensionSetting[
          "node_省教科院发文卡-职能部室"
        ].targetOrg.split("|");
      fullOrgs.forEach((item) => {
        let orgPath = item.split("/");
        this.selectOrgs.push(orgPath[orgPath.length - 2]);
      });
    }
    this.getOrgTree();
  },
  components: {
    selectuser,
  },
  methods: {
    auditshowBack() {
      this.$emit("callback");
    },
    selectOrgschange(value, option) {
      this.selectOrgKeys = [];
      for (let i = 0; i < option.length; i++) {
        this.selectOrgKeys.push(option[i].key);
      }
    },
    async getMatchUsers(selectOrgs, data) {
      await orgApi.getMatchUsers(data).then((res) => {
        selectOrgs = selectOrgs.concat(res).filter((item, index, arr) => {
          return arr.indexOf(item) === index;
        });
      });
    },
    handleSubmit() {
      let extensionSettingData = {};
      if (this.selectOrgs == null || this.selectOrgs.length == 0) {
        this.$message.error("请选职能部室");
        return;
      } else {
        let _d = "";
        this.selectOrgs.forEach((item) => {
          _d = _d + "/" + item + "/主任|";
        });
        _d = _d.substring(0, _d.length - 1);
        extensionSettingData["node_省教科院发文卡-职能部室"] = {
          // /财务{'mainNode':true}
          targetOrg: _d,
          count: this.selectOrgs.length,
        };
        let selectOrgs = "";
        for (let i = 0; i < this.selectOrgKeys.length; i++) {
          selectOrgs =
            selectOrgs + "/?{'leadOrgs':" + this.selectOrgKeys[i] + "}|";
        }
        selectOrgs = selectOrgs.substring(0, selectOrgs.length - 1);

        let data = {};
        data.orgPattern = selectOrgs;
        data.user = localStorage.getItem(this.$commons.User.userName);
        orgApi.getMatchUsers(data).then((res) => {
          extensionSettingData["node_省教科院发文卡-主管领导意见"] = {
            targetUsers: res.toString(),
            count: res.length,
          };
          if (this.data.huiqian == null || this.data.huiqian == "") {
            this.$message.error("请选择会签人");
            return;
          }

          if (this.data.hegao == null || this.data.huiqian == "") {
            this.$message.error("请选择核稿人");
            return;
          }

          extensionSettingData["node_省教科院发文卡-核稿"] = {
            targetUsers: this.data.hegao,
            count: 1,
          };
          extensionSettingData["node_省教科院发文卡-会签意见"] = {
            targetUsers: this.data.huiqian,
            count: this.data.huiqian.split(",").length,
          };
          this.$emit("callback", extensionSettingData);
        });
      }

      // /?{'leadOrgs':23}

      // if(this.selectOrgs == null || this.selectOrgs==''){
      //   this.$message.error('请选职能部室');
      //     return
      // }
      // else {
      //   extensionSettingData['node_省教科院发文卡-职能部室']={
      //     targetOrg:'/河北教育科学研究院/'+this.selectOrgs+'/主任',
      //     count:1
      //   }
      // }
    },
    getOrgTree() {
      orgApi.getTree(true, true).then((res) => {
        if (res.errorCode == this.$commons.RespCode.success) {
          this.orgDates = res.data.tree[0].children;

          if (this.selectOrgs.length > 0) {
            this.orgDates.forEach((item) => {
              if (this.selectOrgs.indexOf(item.name) > -1) {
                this.selectOrgKeys.push(item.id);
              }
            });
          }

          // res.data.tree[0].orgsNumber = this.orgsNumber;
          // this.orgsNumber = this.orgsNumber++;
          // this.orgs = res.data.tree;
          // if (!this.data) {
          //   this.data.zhineng_orgId = res.data.path;
          //   let tree = this.orgs;
          //   for (var i = 0; i < res.data.path.length; ++i) {
          //     var id = res.data.path[i];
          //     var find = tree.filter((n) => n.id == id);
          //     if (i == res.data.path.length - 1) {
          //       this.data.zhineng_orgName = find[0].name;
          //     } else {
          //       tree = find[0].children;
          //     }
          //   }
          // }
        }
      });
    },
    onSearch() {
      this.selectuservisible = true;
    },

    onSearchs() {
      this.selectusersvisible = true;
    },
    selectusersCallback(user) {
      this.data.huiqian = "";
      this.data.huiqianName = "";
      if (user) {
        this.data.huiqian = user.map((u) => u.userName).join(",");
        this.data.huiqianName = user.map((u) => u.realName).join(",");
      }
      this.selectusersvisible = false;
    },
    selectuserCallback(user) {
      this.data.hegao = user.userName;
      this.data.hegaoName = user.realName;
      this.selectuservisible = false;
    },
    showWps() {
      if (!this.data.number) {
        this.$emit("callback", null, true);
      }

      if (this.data.title == "") {
        this.$message.error("请输入标题");
        return;
      }
      if (this.data.dispatchOrg == "") {
        this.$message.error("请输入主送单位");
        return;
      }
      let _number = this.data.number;
      let reg = /(.+)-(.+)-(.+)/;
      if (!reg.test(_number)) {
        this.$message.error("请正确输入发文编号");
        return;
      }
      if (this.data.time == undefined) {
        this.$message.error("请选择发文日期");
        return;
      }
      let templateId = "fawenkaJiaoKeYuanBan";
      if (_number.indexOf("冀教院函") != -1) {
        templateId = "fawenkaJiaoKeYuanHan";
      }
      let fileId = localStorage.getItem("publicFileId");
      let openType = "create";
      if (fileId != null && fileId != "") {
        openType = "edit";
        templateId = fileId;
      }
      if (this.data.contentId != null && this.data.contentId != "") {
        localStorage.setItem("publicFileId", this.data.contentId);
        openType = "edit";
        templateId = this.data.contentId;
      }

      if (
        this.data.status == 3 &&
        this.data.contentId == localStorage.getItem("publicFileId")
      ) {
        openType = "create";
        templateId = "fawenkaJiaoKeYuanBan";
        if (_number.indexOf("冀教院函") != -1) {
          templateId = "fawenkaJiaoKeYuanHan";
        }
        localStorage.removeItem("publicFileId");
      }

      
      if (this.data.print == 1) {
        openType = "readOnly";
      }

      let innerData = {
        id: this.data.id,
        status: this.data.status,
        name: _number.split("-")[0],
        year: _number.split("-")[1],
        number: _number.split("-")[2],
        title: this.data.title,
        headTitle: this.data.dispatchOrg,
        date: this.data.time.format("YYYY年MM月DD日"),
      };
      localStorage.setItem("innerData", JSON.stringify(innerData));
      const jump = this.$router.resolve({
        name: "wpsedit",
        query: {
          //要传的参数
          templateId: templateId,
          fileType: "public",
          openType: openType,
          fileName: "省教科院发文卡_" + _number.replaceAll("-", "_"),
          printType: "custom_fawen",
        },
      });

      window.open(jump.href, "_blank");
    },
  },
};
</script>