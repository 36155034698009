<template>
  <a-spin :tip="loadingTip" :spinning="loading">
    <a-form-model
      ref="auditForm"
      :model="audit"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="拟稿意见" prop="remark">
        <a-textarea v-model="audit.remark" placeholder="拟稿意见" />
      </a-form-model-item>
      <a-form-model-item v-if="manualNext" label="下级审批节点" prop="nextNode">
        <a-select
          v-model="audit.nextNodeId"
          placeholder="请选择"
          allowClear
          @change="nodeChanged"
        >
          <a-select-option
            v-for="item in definitionData"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-if="manualNext" label="下级审批人" prop="nextUser">
        <a-select
          v-model="audit.nextUser"
          placeholder="请先选择审批节点"
          allowClear
        >
          <a-select-option v-for="item in users" :key="item" :value="item">
            {{ item }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-if="cando" :wrapper-col="{ span: 14, offset: 4 }">
        <a-popconfirm
          title="提交后数据不可更改，是否确认？"
          @confirm="onSubmit"
        >
          <a-button type="danger">提交</a-button>
        </a-popconfirm>
        <p-button style="margin-left: 10px" name="取消" @click="cancelSubmit" />
      </a-form-model-item>
    </a-form-model>
  </a-spin>
</template>
  
  <script>
import workflowApi from "@/api/workflow";
export default {
  name: "flow",
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      loadingTip: "加载中...",
      loading: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 16 },
      cando: false,
      audit: {
        remark: "",
        nextUser: "",
        nextNodeId: "",
      },
      definitionData: {},
      users: [],
      manualNext: false,
      rules: {
        remark: [
          {
            required: true,
            message: "请输入拟稿意见",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.getDefinition();
  },
  methods: {
    nodeChanged(v) {
      if (v == null || v == "") {
        return;
      }
      this.audit.nextUser = "";
      var current = this.definitionData.filter((n) => n.id == v);
      if (current.length > 0 && current[0].targetUsers) {
        this.users = current[0].targetUsers.split(",");
        this.$forceUpdate();
      } else {
        this.users = [];
      }
    },
    getDefinition() {
      this.loading = true;
      workflowApi
        .getDefinitionForModel("documentdispatch:" + this.info.type)
        .then((res) => {
          this.loading = false;
          if (res.errorCode === this.$commons.RespCode.success) {
            var data = JSON.parse(res.data.definition);
            var flows = data.start.flows;

            this.definitionData = data.tasks.filter(
              (t) => flows.filter((f) => f.target == t.id).length > 0
            );
            if (
              this.definitionData.length > 0 &&
              this.definitionData[0].manualNext
            ) {
              this.manualNext = true;
            }
            this.cando = true;
          } else {
            this.$message.error(res.errorMsg);
          }
        });
    },
    onSubmit() {
      this.$refs.auditForm.validate((valid) => {
        if (valid) {
          this.$emit("callback", this.audit);
        }
      });
    },

    cancelSubmit() {
      this.$emit("callback");
    },
  },
};
</script>