<template>
    <a-spin :spinning="loading" tip="加载中">
    <div class="wpsFile" style="min-height:600px">

    </div>
    <div style="text-align:center;margin:auto">
        <a-button type="primary" @click="savedoc" style="margin-right:10px">保存</a-button>
        <a-button type="default" @click="close">关闭</a-button>
    </div>
    
</a-spin>
</template>
<script>
import WebOfficeSDK from "@/assets/wps/web-office-sdk-solution-v1.1.27.es";
import login from "@/api/system/login"
import wps from "@/api/work/wps"
export default {
    name:"wps",
    mounted(){
        this.init();
    },
    props:{
        file:{
            type:String
        },
        templateFile:{
            type:String
        }
    },
    data(){
        return{
            wps:null,
            loading:false
        }
    },
    methods:{
        close(){
            this.$emit("callback")
        },
        async savedoc(){
           var res = await this.wps.save();
           if(res.result=='ok' || res.result=='nochange'){
                this.$message.success("保存成功");
                this.$emit("callback");
           }else{
              this.$message.error("保存失败"+res.result);
           }
        },
        init(){
            this.loading=true;
            wps.getWpsUrl(this.file,this.templateFile).then(res=>{
                this.loading=false;
                if (res.errorCode == this.$commons.RespCode.success) {
                    this.initWps(res.data);
                }
            })
           
        },
        async initWps(url){
            var instance = WebOfficeSDK.config({
                url,
                mode:"simple",
                mount: document.querySelector('.wpsFile'),
                token:{
                    token: localStorage.getItem(this.$commons.User.Authentication),
                    timeout: 15 * 60 * 1000, 
                },
                refreshToken:()=>{
                    login.refreshToken().then(res=>{
                        return Promise.resolve({
                            token: localStorage.getItem(this.$commons.User.Authentication),
                            timeout: 15 * 60 * 1000, 
                        });
                    });
                    
                }
            })
   
            instance.ApiEvent.AddApiEventListener("fileOpen",function(){
                console.log('打开成功')
            })
            await instance.ready();
            this.wps =instance;
        }        
    }
}
</script>