import request from "@/request/index";  
const part_url = "/share/weboffice/";

export default{

    getWpsUrl(fileId,template) {
        let url = part_url + "getWpsUrl";
        return request.get(url,{fileId,template}, {});
    },
    
}
