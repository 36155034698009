<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <div class="tableList">
      <div class style="position: relative">
        <a-form-model ref="qingshiform" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol"
          style="width: 70%; padding: 30px; margin: auto">
          <template v-if="form.type != '会议纪要'">
            <a-form-model-item label="标题" prop="title">
              <a-input v-model="form.title" />
            </a-form-model-item>
            <a-form-model-item label="抬头" prop="headTitle">
              <a-input v-model="form.headTitle" />
            </a-form-model-item>
            <!-- <a-form-model-item label="文号" v-if="form.type == '通知'">
              <documentnumber :ty="tz" ref="number1" :v="form.number" />
            </a-form-model-item> -->
            <a-form-model-item label="落款" prop="signOff">
              <a-input v-model="form.signOff" />
            </a-form-model-item>
            <a-form-model-item label="时间" prop="time">
              <a-date-picker v-model="form.time" />
            </a-form-model-item>
            <a-form-model-item label="正文">
              <a-button type="link" @click="showWps(form.print == 1,false)">
                <span v-if="form.status == 0">填写正文</span>
                <span v-else>查看正文</span>
              </a-button>
            </a-form-model-item>
            <a-form-model-item label="协同部室" v-if="form.type.indexOf('通知') == -1">
              <a-select v-model="selectOrgs" mode="multiple" style="width: 450px;"
                v-if="form.status == 0 || form.status == 3" @change="selectOrgschange">
                <a-select-option v-for="item in orgDates" :key="item.id" :value="item.name">
                  {{ item.name }}
                </a-select-option>
              </a-select>
              <div v-else>{{ selectOrgs.toString() }}</div>
            </a-form-model-item>
            <a-form-model-item label="附件">
              <a-upload :action="uploadFileUrl" :fileList="fileList" @change="handleFileChange" @preview="showWpsRead"
                :remove="upLoadRemove">
                <div v-if="this.form.status == 0 || form.status == 3">
                  <a-button><a-icon type="upload" />上传文件</a-button>
                </div>
              </a-upload>
            </a-form-model-item>
          </template>
          <template v-else>
            <a-form-model-item label="文号" prop="number">
              <a-input v-model="form.number" v-show="false" />
              <documentnumber :ty="jy" ref="number2" :v="form.number" @callback="numberChangeAction" />
            </a-form-model-item>
            <a-form-model-item label="单位" prop="dispatchOrg">
              <a-input v-model="form.dispatchOrg" readOnly />
            </a-form-model-item>
            <!-- <a-form-model-item label="签发" prop="otherExtension.sign">
              <a-input v-model="form.otherExtension.sign" />
            </a-form-model-item> -->
            <a-form-model-item label="名称" prop="otherExtension.name">
              <a-input v-model="form.otherExtension.name" />
            </a-form-model-item>
            <a-form-model-item label="时间" prop="time">
              <a-date-picker v-model="form.time" />
            </a-form-model-item>
            <a-form-model-item label="地点" prop="otherExtension.position">
              <a-input v-model="form.otherExtension.position" />
            </a-form-model-item>
            <a-form-model-item label="主持人" prop="otherExtension.zhuchiren">
              <a-input v-model="form.otherExtension.zhuchiren" />
            </a-form-model-item>
            <a-form-model-item label="出席" prop="otherExtension.chuxi">
              <a-input v-model="form.otherExtension.chuxi" />
            </a-form-model-item>
            <a-form-model-item label="列席" prop="otherExtension.liexi">
              <a-input v-model="form.otherExtension.liexi" />
            </a-form-model-item>
            <a-form-model-item label="记录" prop="otherExtension.jilu">
              <a-input v-model="form.otherExtension.jilu" />
            </a-form-model-item>
            <a-form-model-item label="正文">
              <a-button type="link" @click="showWps(form.print == 1, false)">
                <span v-if="form.status == 0">填写正文</span>
                <span v-else>查看正文</span>
              </a-button>
              <a-button type="link" v-if="form.status == 2 && form.type == '会议纪要'"
                @click="showWps(true, true)">打印（手写体）</a-button>
            </a-form-model-item>
          </template>
        </a-form-model>
        <template v-if="!detailMode">
          <div style="text-align: center">
            <template v-if="form.status == 0 || form.status == 3">
              <a-button type="primary" style="margin-right: 5px" @click="handleSubmit(0)">保存</a-button>
              <a-popconfirm title="提交后数据不可更改，是否确认？" @confirm="handleSubmit(1)">
                <a-button type="danger" style="margin-right: 5px">提交</a-button>
              </a-popconfirm>
            </template>
            <template v-else-if="form.status == 1 && form.processExecuteStatus">
              <a-button type="primary" style="margin-right: 5px" @click="showaudit()">审批</a-button>
            </template>
            <template v-else-if="form.status == 2 && allowFinish">
              <a-button type="primary" style="margin-right: 5px" @click="finishTask()">办理</a-button>
            </template>
            <a-button type="default" @click="cancelClick" class="cancelBtn">取消</a-button>
          </div>
        </template>
      </div>
      <a-modal title="审批" v-model="auditModal.visible" width="40%" :footer="false" destroyOnClose :maskClosable="false">
        <audit :info="auditModal.info" @callback="auditCallBack" />
      </a-modal>
      <a-modal title="正文" v-model="wpsModal.visible" width="90%" :footer="false" destroyOnClose :maskClosable="false">
        <wps :file="form.remoteDocument" :templateFile="form.type" @callback="wpsCallBack" />
      </a-modal>
    </div>
  </a-spin>
</template>

<script>
import documentnumber from "../components/documentnumber.vue";
import documentdispatchApi from "@/api/work/documentdispatch";
import workflowapi from "@/api/workflow";
import wps from "../components/wps.vue";
import request from "@/request";
import workflowApi from "@/api/workflow";
import orgApi from "@/api/org";
export default {
  props: {
    info: {
      type: Object,
      default: () => { },
    },
    detailMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tz: "tz",
      jy: "jy",
      orgs: [],
      taskId: "",
      selectOrgs: [],
      selectOrgKeys: [],
      orgDates: "",
      currentOrgPath: "",
      currentOrgId: "",
      rootName: "",
      allowFinish: false,
      form: {
        status: 0,
        number: "",
        otherExtension: {},
        type: "",
      },
      auditModal: {
        info: null,
        visible: false,
      },
      wpsModal: {
        visible: false,
        file: null,
      },
      uploadFileUrl: "",
      labelCol: { span: 4 },
      wrapperCol: { span: 16 },
      isloading: false,
      loadingTip: "加载中...",
      fileList: [],
      selectuservisible: false,
      rules: {
        title: [
          {
            required: true,
            message: "请输入标题",
            trigger: "blur",
          },
        ],
        number: [
          {
            required: true,
            message: "请编辑文号",
            trigger: "change",
          },
        ],
        headTitle: [
          {
            required: true,
            message: "请输入抬头",
            trigger: "blur",
          },
        ],

        dispatchOrg: [
          {
            required: true,
            message: "请输入单位",
            trigger: "blur",
          },
        ],
        // "otherExtension.sign": [
        //   {
        //     required: true,
        //     message: "请输入签发",
        //     trigger: "blur",
        //   },
        // ],
        "otherExtension.position": [
          {
            required: true,
            message: "请输入地点",
            trigger: "blur",
          },
        ],
        "otherExtension.zhuchiren": [
          {
            required: true,
            message: "请输入主持人",
            trigger: "blur",
          },
        ],
        "otherExtension.chuxi": [
          {
            required: true,
            message: "请输入出席",
            trigger: "blur",
          },
        ],
        "otherExtension.liexi": [
          {
            required: true,
            message: "请输入列席",
            trigger: "blur",
          },
        ],
        "otherExtension.jilu": [
          {
            required: true,
            message: "请输入记录",
            trigger: "blur",
          },
        ],
        "otherExtension.name": [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
        time: [
          {
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
      },
    };
  },
  mounted() {
    localStorage.removeItem("tsFileId");
    localStorage.removeItem("bbFileId");
    localStorage.removeItem("hyFileId");
    this.$nextTick(function () {
      this.uploadFileUrl = request.getUploadUrl("document");
      if (this.info.id) {
        this.getData();
      } else {
        this.form.type = this.info.type;
        this.form.remoteDocument = this.$util.uuid();
      }
    });
    this.getOrgTree();
  },
  components: {
    documentnumber,
    wps,
  },
  methods: {
    upLoadRemove() {
      return this.form.status == 0 || this.form.status == 3;
    },
    wpseditCallback() {
      alert();
    },
    numberChangeAction(data) {
      if (data == "院政字")
        this.$set(this.form, "dispatchOrg", "河北省教育科学研究院");
      if (data == "院党字")
        this.$set(
          this.form,
          "dispatchOrg",
          "中共河北省教育科学研究院总支部委员会"
        );
    },
    showWpsRead(file) {
      let type = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (
        type.toLowerCase().indexOf("doc") <= -1 &&
        type.toLowerCase().indexOf("xls") <= -1 &&
        type.toLowerCase().indexOf("ppt") <= -1 &&
        type.toLowerCase().indexOf("pdf") <= -1
      ) {
        window.open(file.response.url, "_blank");
      } else {
        let fileId =
          "file" +
          file.response.url
            .substring(
              file.response.url.lastIndexOf("/") + 1,
              file.response.url.lastIndexOf(".")
            )
            .replaceAll("-", "");
        let url = file.response.url.substring(
          file.response.url.indexOf("oabucket")
        );
        let type = file.response.url.substring(
          file.response.url.lastIndexOf(".") + 1
        );
        localStorage.removeItem("fileRrl");
        localStorage.setItem("fileRrl", url);
        localStorage.removeItem("fileName");
        localStorage.setItem("fileName", file.name);
        localStorage.removeItem("fileId");
        localStorage.setItem("fileId", fileId);
        localStorage.removeItem("fileTpye");
        localStorage.setItem("fileTpye", type);
        const jump = this.$router.resolve({
          name: "wpsRead",
          query: {
            //要传的参数
            fileName: file.name,
          },
        });

        window.open(jump.href, "_blank");
      }
    },
    showWps(readOnly, hand) {
      // hand 打印手写体，只在会议纪要使用
      if (readOnly) { // 只读模式
        localStorage.setItem("fileId", this.form.contentId);
        let hyData = {
          id: this.form.id,
          status: this.form.status,
          sign: this.form.otherExtension.sign,
          print: this.form.print,
          readOnly: true,
        };
        if (hand) {
          let signPic = "";
          let fin = this.form.auditRecords.find(
            (a) =>
              a.auditUserName == this.form.otherExtension.sign &&
              a.taskKey == "院长"
          );
          if (fin) {
            signPic = fin.auditUserSignPicture;
          }
          if (signPic != "") {
            signPic = "http://localhost:9000/" +
              signPic.substring(signPic.indexOf("uas"));
          }
          hyData.signPic = signPic;
        }
        localStorage.setItem("hyData", JSON.stringify(hyData));
        const jump = this.$router.resolve({
          name: "wpsRead",
          query: {
            //要传的参数
            as: true,
            readOnly: true,
            printType: "custom_fawen",
          },
        });
        window.open(jump.href, "_blank");
        return;
      }
      
      if (this.$refs.number2) {
        this.form.number = this.$refs.number2.getNumber();
      } else if (this.$refs.number1) {
        this.form.number = this.$refs.number1.getNumber();
      }

      let __number;
      let fileId = "";
      let fileType = "";
      let templateId = "";
      let ff = Object.assign({}, this.form);
      this.$refs.qingshiform.validate((valid) => {
        if (valid) {
          if (this.form.type == "会议纪要") {
            delete ff.currentNode;
            delete ff.currentNodeName;
            delete ff.processId;
            delete ff.taskId;
            delete ff.taskKey;
            delete ff.taskUsers;
            delete ff.taskRoles;
            delete ff.processStatus;
            if (valid) {
              ff = JSON.stringify(this.form);
            }
            __number = this.$refs.number2.getNumber();
            fileId = localStorage.getItem("hyFileId");
            fileType = "hy";
            templateId = "A0030001";
            localStorage.setItem("innerData", ff);
          } else if (this.form.type == "报告" || this.form.type == "请示") {
            __number = "情况报告";
            fileId = localStorage.getItem("bbFileId");
            fileType = "bb";
            templateId = "A0020001";
            let data = {
              id: this.form.id,
              status: this.form.status,
              print: this.form.print,
              title: this.form.title,
              headTitle: this.form.headTitle,
              signOff: this.form.signOff,
              date: this.form.time
                ? this.form.time.format("YYYY年MM月DD日")
                : "",
            };
            localStorage.setItem("innerData", JSON.stringify(data));
          } else {
            //通知
            __number = this.form.type;
            fileId = localStorage.getItem("tsFileId");
            fileType = "ts";
            if (this.info.type == "省教科院通知") {
              templateId = "A0010002";
            } else if (this.info.type == "省规划办通知") {
              templateId = "A0010004";
            } else if (this.info.type == "省教育学会通知") {
              templateId = "A0010003";
            }
            let data = {
              id: this.form.id,
              status: this.form.status,
              print: this.form.print,
              title: this.form.title,
              headTitle: this.form.headTitle,
              date: this.form.time
                ? this.form.time.format("YYYY年MM月DD日")
                : "",
            };
            localStorage.setItem("innerData", JSON.stringify(data));
          }

          let openType = "create";
          if (fileId && fileId != "") {
            openType = "edit";
            templateId = fileId;
          }
          if (this.form.contentId && this.form.contentId != "") {
            openType = "edit";
            templateId = this.form.contentId;
          }
          
          if (this.form.print == 1) {
            openType = "readOnly";
          }
          
          const jump = this.$router.resolve({
            name: "wpsedit",
            query: {
              //要传的参数
              templateId: templateId,
              number: __number,
              fileType: fileType,
              openType: openType,
              printType: "custom_fawen",
            },
          });
          window.open(jump.href, "_blank");
        }
      });
    },
    wpsCallBack() {
      this.wpsModal.visible = false;
    },
    showaudit() {
      this.auditModal.info = this.form;
      this.auditModal.visible = true;
    },
    async auditCallBack(data) {
      let close = true;
      this.auditModal.visible = false;
      if (data != null && data.updateModel && this.form.type == "会议纪要") {
        close = false;
        this.loading = true;
        this.loadingTip = "保存中...";
        this.form.otherExtension.sign = data.auditUserName;
        let formData = {
          id: this.info.id,
          otherExtension: JSON.stringify(this.form.otherExtension),
          type: this.form.type,
          creatorOrgId: this.form.creatorOrgId,
          creatorOrgName: this.form.creatorOrgName,
          updateOnly: true,
        };
        let res = await documentdispatchApi.addOrUpdate(formData);
        if (res.errorCode == "0000") {
          this.loading = false;
          close = true;
        }
      }
      if (close) {
        this.$emit("callback", data != null);
      }
    },
    handleFileChange(fileInfo) {
      this.fileList = fileInfo.fileList;
      if (fileInfo.file.status == "error") {
        this.$message.error("上传失败");
      }
    },
    getData() {
      this.loading = true;
      documentdispatchApi.getData(this.info.id).then((res) => {
        this.loading = false;
        if (res.errorCode == this.$commons.RespCode.success) {
          res.data.time = this.$moment(res.data.time, "YYYY-MM-DD");
          if (res.data.otherExtension) {
            res.data.otherExtension = JSON.parse(res.data.otherExtension);
          }
          if (res.data.attachment) {
            var d = JSON.parse(res.data.attachment);
            var files = [];
            for (var i = 1; i <= d.length; ++i) {
              var a = d[i - 1];
              files.push({
                uid: i,
                name: a.title,
                status: "done",
                response: a,
                url: a.url,
              });
            }
            this.fileList = files;
          }
          this.form = res.data;
          if (
            this.info &&
            this.info.taskId &&
            this.info.taskId != this.form.taskId
          ) {
            this.form.taskId = this.info.taskId;
          }
          if (this.form.remoteDocument == null) {
            this.form.remoteDocument = this.$util.uuid();
          }
          if (this.form.selectOrgs != null) {
            let _ss = JSON.parse(this.form.selectOrgs);
            this.selectOrgKeys = _ss.selectOrgKeys;
            this.selectOrgs = _ss.selectOrgs;
          }
          this.getTask(res.data.processId);
        }
      });
    },
    async finishTask() {
      if (this.taskId == "" || this.taskId == null) {
        this.$message.error("已办理");
        return;
      }
      this.worktaskloading = true;
      var res = await workflowApi.finishTask(this.taskId);
      this.worktaskloading = false;
      if (res.errorCode === this.$commons.RespCode.success) {
        this.$message.success("操作成功");
        this.getData();
      } else {
        this.$message.error(res.errorMsg);
      }
    },
    getTask(id) {
      this.loading = true;
      this.taskId = "";
      this.allowFinish = false;
      workflowapi.getTask(id).then((res) => {
        this.loading = false;
        if (res.errorCode == this.$commons.RespCode.success) {
          if (res.data != null && res.data != "" && res.data.length > 0) {
            this.taskId = res.data[0].id;
            this.allowFinish = true;
            this.$forceUpdate();
          }
        }
      });
    },
    selectOrgschange(value, option) {
      this.selectOrgKeys = [];
      for (let i = 0; i < option.length; i++) {
        this.selectOrgKeys.push(option[i].key);
      }
    },
    getOrgTree() {
      orgApi.getTree(true, true).then((res) => {
        if (res.errorCode == this.$commons.RespCode.success) {
          let currentOrgId = res.data.path[res.data.path.length - 1];
          this.rootName = res.data.tree[0].path;
          this.orgDates = res.data.tree[0].children.filter(
            (n) => n.id != currentOrgId
          );
          let _d = res.data.tree[0].children.filter(
            (n) => n.id == currentOrgId
          )[0];
          this.currentOrgPath = _d.path;
          this.currentOrgId = _d.id;
        }
      });
    },
    handleSubmit(status) {
      if (
        this.form.type.indexOf("通知") != -1 && localStorage.getItem("tsFileId") != null
      ) {
        if (this.form.status == 3 && this.form.contentId == localStorage.getItem("tsFileId")) {
          this.$message.error("请先重新编辑和保存正文");
          return;
        }
        this.form.contentId = localStorage.getItem("tsFileId");
      }
      if (
        this.form.type == "请示" && localStorage.getItem("bbFileId") != null
      ) {
        if (this.form.status == 3 && this.form.contentId == localStorage.getItem("bbFileId")) {
          this.$message.error("请先重新编辑和保存正文");
          return;
        }
        this.form.contentId = localStorage.getItem("bbFileId");
      }
      if (
        this.form.type == "报告" && localStorage.getItem("bbFileId") != null
      ) {
        if (this.form.status == 3 && this.form.contentId == localStorage.getItem("bbFileId")) {
          this.$message.error("请先重新编辑和保存正文");
          return;
        }
        this.form.contentId = localStorage.getItem("bbFileId");
      }
      if (
        this.form.type == "会议纪要" && localStorage.getItem("hyFileId") != null
      ) {
        if (this.form.status == 3 && this.form.contentId == localStorage.getItem("hyFileId")) {
          this.$message.error("请先重新编辑和保存正文");
          return;
        }
        this.form.contentId = localStorage.getItem("hyFileId");
      }

      if (this.form.contentId == null) {
        this.$message.error("请编辑和保存正文");
        return;
      }
      let extensionSettingData = {};
      let leadOrgs = "";
      let mainNode = "";
      if (this.selectOrgs == null || this.selectOrgs.length == 0) {
        // this.$message.error('请选协同部室');
        // return
      } else {
        this.selectOrgKeys.forEach((item) => {
          leadOrgs = leadOrgs + "/?{'leadOrgs':" + item + "}|";
        });
        this.selectOrgs.forEach((item) => {
          mainNode =
            mainNode + "/" + this.rootName + "/" + item + "/{'mainNode':true}|";
        });
      }
      leadOrgs = leadOrgs + "/?{'leadOrgs':" + this.currentOrgId + "}";
      mainNode = mainNode + "/" + this.currentOrgPath + "/{'mainNode':true}";
      extensionSettingData["key_部室负责人"] = {
        targetOrg: mainNode,
        count: this.selectOrgs.length + 1,
      };
      let leadOrgs_data = {};
      leadOrgs_data.orgPattern = leadOrgs;
      leadOrgs_data.user = localStorage.getItem(this.$commons.User.userName);
      orgApi.getMatchUsers(leadOrgs_data).then((res) => {
        extensionSettingData["key_分管院领导"] = {
          targetUsers: res.toString(),
          count: res.length,
        };
        this.form.extensionSetting = JSON.stringify(extensionSettingData);
        let selectOrg = {
          selectOrgs: this.selectOrgs,
          selectOrgKeys: this.selectOrgKeys,
        };
        this.form.selectOrgs = JSON.stringify(selectOrg);

        this.$refs.qingshiform.validate((valid) => {
          if (valid) {
            let formData = { ...this.form, status };

            if (this.form.otherExtension) {
              formData.otherExtension = JSON.stringify(
                this.form.otherExtension
              );
            }
            formData.time = this.form.time.format("YYYY-MM-DD");
            if (this.$refs.number2) {
              formData.number = this.$refs.number2.getNumber();
            } else if (this.$refs.number1) {
              formData.number = this.$refs.number1.getNumber();
            }
            if (this.fileList.length > 0) {
              var file = [];
              this.fileList.forEach((f) => {
                file.push({
                  title: f.response.title,
                  url: f.response.url,
                });
              });
              formData.attachment = JSON.stringify(file);
            }
            this.loading = true;
            this.loadingTip = "保存中...";
            if (this.info) {
              formData.id = this.info.id;
            }
            if (this.form.type == "会议纪要") {
              formData.title = formData.number + "会议纪要";
            }
            delete formData.auditRecords;
            //添加
            documentdispatchApi
              .addOrUpdate(formData)
              .then((res) => {
                if (res.errorCode == this.$commons.RespCode.success) {
                  this.$message.success(res.errorMsg);
                  this.$emit("callback", true);
                } else {
                  this.$message.error(res.errorMsg);
                }
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          } else {
            return false;
          }
        });
      });
    },
    cancelClick() {
      this.$emit("callback", false);
    },
  },
};
</script>
<style>
.edui-default .edui-toolbar {
  line-height: 20px !important;
}
</style>